/*
 * @Description: md5 工具类
 * @Author: 琢磨先生
 * @Date: 2022-06-01 21:00:53
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-01 21:06:34
 */
import SparkMD5 from "spark-md5";

export default {
  /**
   * 获取文件的md5值
   * @param {*} file
   * @returns
   */
  fileMd5(file) {
    return new Promise((resolve, reject) => {
      var blobSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice,
        chunkSize = 2097152,
        chunks = Math.ceil(file.size / chunkSize),
        currentChunk = 0,
        spark = new SparkMD5.ArrayBuffer(),
        fileReader = new FileReader();

      fileReader.onload = function (e) {
        spark.append(e.target.result);
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
        } else {
          resolve(spark.end());
        }
      };

      fileReader.onerror = function () {
        reject("获取文件md5值错误");
      };

      function loadNext() {
        var start = currentChunk * chunkSize,
          end = start + chunkSize >= file.size ? file.size : start + chunkSize;

        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }

      loadNext();
    });
  },
};
